.flatpickr-input::selection {
  display: none;
}
.flatpickr-input:focus {
  border: 2px solid #61d9ca;
  outline: none;
}

.focuesBorderPrimary {
  /* display: none !important; */
  border-color: red !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-t3ipsp-control {
  border-color: #61d9ca !important ;
  box-shadow: 0 0 1px #61d9ca !important ;
}

.responsive-width-control {
  max-width: 100vw !important;
}

/* overwrited css from core css and theme default  */

.bg-menu-theme.menu-vertical .menu-item.active > .menu-link:not(.menu-toggle) {
  background: rgb(97, 217, 202) !important;
  box-shadow: 0px 2px 6px 0px rgba(97, 217, 202, 0.48) !important;
}

.card {
  background-clip: padding-box;
  /* box-shadow: 0 0.125rem 0.25rem rgba(97, 217, 202, 0.3);  edited */
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1) !important;
}

.navbar-detached {
  /* box-shadow: 0 0.125rem 0.25rem rgba(97, 217, 202, 0.3); edited */
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1) !important;
}
/* =================================================== */
