.api-key-actions {
  position: absolute !important;
  top: 0.75rem;
}
html:not([dir=rtl]) .api-key-actions {
  right: 0.5rem;
}
[dir=rtl] .api-key-actions {
  left: 0.5rem;
}


.v-alert {
  display: grid;
  flex: 1 1;
  grid-template-areas:
      "prepend content append close"
      ". content . .";
  grid-template-columns: max-content auto max-content max-content;
  position: relative;
  padding: 16px;
  overflow: hidden;
  --v-border-color: currentColor;
  border-radius: 6px;
  color: red;
  background-color: #fceaeb;
}
.v-alert-title {
  align-items: center;
  align-self: center;
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  -webkit-hyphens: auto;
  hyphens: auto;
  letter-spacing: .0125em;
  line-height: 1.5rem;
  overflow-wrap: normal;
  text-transform: none;
  word-break: normal;
  word-wrap: break-word;
}

.modal-style-blur .modal-backdrop {
  --bs-backdrop-opacity: 1;
  background-color: #00000080;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
